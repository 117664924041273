import en from "./translations/en.json";
import fr from "./translations/fr.json";
import jp from "./translations/jp.json";

import { defineI18nConfig } from "#i18n";

export const allowedLanguages = [
    "en",
    "fr",
    "jp"
];
export const defaultLanguage = "fr";

function getLanguageFromNavigator (): string | null {
    const language = navigator.language.split("-")[0];
    if (allowedLanguages.includes(language)) {
        return language;
    }
    return null;
}

function getLanguageFromLocalStorage (): string | null {
    const language = localStorage.getItem("locale");
    if (language && allowedLanguages.includes(language)) {
        return language;
    }
    return null;
}

export function getLanguage (): string {
    return getLanguageFromLocalStorage() || getLanguageFromNavigator() || defaultLanguage;
}

export default defineI18nConfig(() => ({
    legacy: false,
    locale: getLanguage(),
    fallbackLocale: defaultLanguage,
    messages: {
        fr,
        en,
        jp
    }
}));
